























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SensorIcon from '@/components/SensorIcon.vue';

@Component({
  components: {
    SensorIcon,
  },
})
export default class Analyzers extends Vue {
  public $store: any;
  public $q: any;
  public analyzers: any = [];
  public filter: string = '';
  public pagination: any = {
    rowsPerPage: 10,
    rowsNumber: 0,
    page: 1,
  };
  public loading: boolean = true;
  private columns: any = [
    {
      name: 'type',
      required: true,
      label: 'Type',
      sortable: true,
      headerStyle: 'width: 50px',
    },
    {
      name: 'serial',
      required: true,
      label: 'Numéro de série',
      align: 'left',
      sortable: true,
      headerStyle: 'max-width: 100px',
    },
    {
      name: 'organisation',
      label: 'Organisation',
      align: 'left',
      sortable: true,
    },
    {
      name: 'area',
      label: 'Pièce',
      align: 'left',
      sortable: true,
    },
    {
      name: 'last_updated_at',
      label: 'Dernier envoi',
      align: 'left',
      sortable: true,
    },
  ];

  public async created() {
    this.refreshDefault();
  }

  private refresh(limit: number, page: number, sortBy: any, descending: boolean, filter: any) {
    this.loading = true;
    this.$store.dispatch('loadAdminAnalyzers', {
      limit,
      page: page - 1,
      filter,
      sort_by: sortBy,
      descending,
    }).then((response: any) => {
      this.analyzers = response.analyzers;
      this.pagination.rowsNumber = response.count;
      this.pagination.page = page;
      this.pagination.sortBy = response.sort_by;
      this.pagination.descending = response.descending;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private editEntry(entryId: number) {
    this.$router.push(`/admin/analyzer/${entryId}`);
  }

  private refreshDefault() {
    this.refresh(
      this.pagination.rowsPerPage,
      this.pagination.page,
      this.pagination.sortBy,
      this.pagination.descending,
      null);
  }

  private onRequest(props: any) {
    this.pagination.rowsPerPage = props.pagination.rowsPerPage;
    this.refresh(
      props.pagination.rowsPerPage,
      props.pagination.page,
      props.pagination.sortBy,
      props.pagination.descending,
      props.filter,
    );
  }

}
