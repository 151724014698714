






import { Component, Prop, Vue } from 'vue-property-decorator'
import { getCardTypeIcon, getCardTypeName } from '@/icons'

@Component
export default class SensorIcon extends Vue {
  @Prop({ required: true }) cardType!: string
  @Prop({ default: 'xs' }) size!: string
  @Prop({ default: false }) withName!: boolean

  getCardTypeIcon (cardType: string) {
    return getCardTypeIcon(cardType)
  }

  getCardTypeName (cardType: string) {
    return getCardTypeName(cardType)
  }
}
