const icons = [
    'qai-good',
    'qai-bad',
    'head',
    'gestion',
    'bat',
    'bat2',
    'arrow-down',
    'arrow-up',
    'health',
    'humidity',
    'temperature',
    'poi',
    'confinement',
    'covid',
    'capteur-mural',
    'capteur-pyramide',
    'home',
    'user',
    'power',
    'cloud-online',
    'cloud-offline',
    'nodata',
    'question',
    'pollutant-CO',
    'pollutant-CO2',
    'pollutant-COV',
    'pollutant-H',
    'pollutant-NO2',
    'pollutant-PM',
    'pollutant-T',
];

const cardTypeIconMapping = {
    'veilair': 'detecteur-veilair',
    'eqs': 'detecteur-eqs',
    'airly': 'detecteur-eqs',
    'cozyair-v2-mural': 'detecteur-mural',
}

const cardTypeIconDefault = 'detecteur'

function getCardTypeIcon(cardType: string) {
    return 'asvg:' + (cardTypeIconMapping[cardType] || cardTypeIconDefault);
}

function getCardTypeName(cardType: string) {
    return cardType.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
}


export { icons, getCardTypeIcon, getCardTypeName };
